import { FC } from "react";

import styles from "./styles.module.scss";

interface IProps {
  top?: number;
  bottom?: number;
  isMenu?: boolean;
}

const Divider: FC<IProps> = ({ bottom, top, isMenu }) => (
  <div
    className={styles.divider}
    style={{
      marginTop: top,
      marginBottom: bottom,
      backgroundColor: isMenu ? "#c4c4c4" : "#141d19",
    }}
  />
);

export default Divider;
