import { FC } from "react";
import styles from "./styles.module.scss";
const PresaleGuideSneakPeak: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.title}>INSIDE THE GUIDE: A SNEAK PEEK</div>
    <div className={styles.items}>
      <div className={styles.item}>
        <div className={`${styles.image} ${styles.image1}`}></div>
        <div className={styles.text}>
          <div className={styles.head}>1 Chapter:</div>
          <div className={styles.subtitle}>Sex Education for Adults</div>
          <div className={styles.description}>
            Tips on understanding the clitoris, the importance of foreplay, and
            exploring without ego
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={`${styles.image} ${styles.image2}`}></div>
        <div className={styles.text}>
          <div className={styles.head}>2 Chapter:</div>
          <div className={styles.subtitle}>A Holistic Approach to Sex</div>
          <div className={styles.description}>
            Healthy living checkpoints, mental wellness practices, and self-care
            routines for confidence.
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={`${styles.image} ${styles.image3}`}></div>
        <div className={styles.text}>
          <div className={styles.head}>3 Chapter:</div>
          <div className={styles.subtitle}>Intimacy Beyond the Bedroom</div>
          <div className={styles.description}>
            Daily affection rituals, planning date nights, and building
            emotional closeness.
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={`${styles.image} ${styles.image4}`}></div>
        <div className={styles.text}>
          <div className={styles.head}>4 Chapter:</div>
          <div className={styles.subtitle}>
            The Other "C" Word – Communication
          </div>
          <div className={styles.description}>
            Using "I" statements, monthly “Desire Inventory” check-ins, and
            creating a safe space for feedback.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PresaleGuideSneakPeak;
