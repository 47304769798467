import { FC } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";

const PresaleGuideProgress: FC<{ progress: number }> = ({ progress }) => (
  <div className={styles.wrapper}>
    <div className={styles.progress}>
      <div className={styles.start}>
        <Icon type={IconTypes.CHECK} />
      </div>
      <div className={`${styles.bar} ${progress >= 1 && styles.active}`}></div>
      <div className={`${styles.bar} ${progress >= 2 && styles.active}`}></div>
      <div className={`${styles.bar} ${progress >= 3 && styles.active}`}></div>
      <div className={`${styles.bar} ${progress >= 4 && styles.active}`}></div>
      <div className={`${styles.bar} ${progress >= 5 && styles.active}`}></div>
      <div className={styles.end}>
        <Icon type={IconTypes.LOCK} size={18} />
      </div>

      <div className={styles.gear}>
        <Icon type={IconTypes.GEAR} size={20} />

        <div className={styles.tooltip}>Recommended Upgrades</div>
      </div>
    </div>
    <div className={styles.titles}>
      <div className={styles.title}>Your Plan</div>
      <div className={styles.title}>Access</div>
    </div>
  </div>
);

export default PresaleGuideProgress;
