import { FC } from "react";
import styles from "./styles.module.scss";
import ReactCompareImage from "react-compare-image";
import before from "./../../../assets/images/before.png";
import after from "./../../../assets/images/after.png";

const PresaleGuideImageCompare: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.title}>TRANSFORM YOUR RELATIONSHIP</div>
    <div className={styles.slider}>
      <ReactCompareImage leftImage={before} rightImage={after} />
    </div>
  </div>
);

export default PresaleGuideImageCompare;
