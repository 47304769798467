import { FC } from "react";
import styles from "./styles.module.scss";

const Loading: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.logo}>
      <svg id="logo-text" viewBox="0 0 160 80" width="131px" height="64px">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.42801 23.9262L5 28.3542V72.6347L9.42801 77.0627L5 77.0627H31.5683V59.3506H22.7122V41.6384H31.5683L31.5683 28.3543L27.1402 23.9262H9.42801ZM31.5683 77.0627H27.1402L31.5683 72.6347V77.0627ZM22.7122 32.7823H13.8561V68.2066H22.7122V32.7823Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.4243 23.9262L35.9963 28.3542V72.6347L40.4243 77.0627L35.9963 77.0627H62.5646V23.9262L62.5646 23.9262V28.3543L58.1365 23.9262H40.4243ZM62.5646 77.0627H58.1365L62.5646 72.6347V77.0627ZM53.7085 32.7823H44.8524V68.2066H53.7085V32.7823Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.864 23.9262L97.4356 28.3542L97.4355 72.6347L101.864 77.0627L97.4356 77.0627H124.004L119.576 77.0627L124.004 72.6347V28.3542L119.576 23.9262H101.864ZM115.148 41.6384H124.004V59.3506H115.148V41.6384ZM115.148 32.7823H106.292V68.2066H115.148V32.7823Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.144 23.9262V46.0664H137.288V23.9262L128.432 23.9262V77.0627H155V23.9262L146.144 23.9262ZM146.144 54.9225H137.288V77.0627H146.144V54.9225Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.0996 23.9262H72.5277L87.4723 23.9262L95.2214 77.0627H86.2716L84.9801 68.2067H75.0198L73.7283 77.0627H64.7786L71.9641 27.7907L68.0996 23.9262ZM83.6886 59.3506H76.3114L80 34.0572L83.6886 59.3506Z"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.6973 4H69.4832V4.00001H67.2693V6.21403H69.4832V15.0701H71.6973V6.21403H73.9114V4.00001H71.6973V4ZM82.2139 8.42805H82.2139V10.6421H82.2139V15.0701H79.9999V10.6421H77.7859V15.0701H75.5719V10.6421V8.42805V4H77.7859V8.42805H79.9999V4H82.2139V8.42805ZM86.6419 4H91.07V6.21402H86.6419V8.42805H89.9629V10.6421H86.6419V12.8561H91.07V15.0701H84.4279V15.0701V12.8561V10.6421V8.42805V6.21402V4H86.6419Z"
          fill="#FF6D03"
        ></path>
      </svg>
    </div>
  </div>
);

export default Loading;
