import { FC } from "react";
import Icon, { IconTypes } from "../Icon";

import styles from "./styles.module.scss";

interface IProps {
  toggler: () => void;
  isPaymentSuccess?: boolean;
}

const Header: FC<IProps> = ({ toggler, isPaymentSuccess }) => {
  return (
    <header className={styles.header}>
      <img
        src="https://join.the.coach/img/content/Logotip.png"
        width="40px"
        height="40px"
        alt="logo"
      />
      <div className={styles.burger} onClick={toggler}>
        <Icon
          type={IconTypes.BURGER}
          size={{ height: 35, width: 35 }}
          color={isPaymentSuccess ? "#fff" : "#00000"}
        />
      </div>
    </header>
  );
};

export default Header;
