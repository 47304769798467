import { FC } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";
import book from "./../../../assets/images/book.png";

const Point = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className={styles.point}>
      <div className={styles.icon}>
        <Icon type={IconTypes.CHECK} size={10} />
      </div>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description} </div>
      </div>
    </div>
  );
};

const PresaleGuidePoints: FC = () => (
  <div className={styles.wrapper}>
    <img src={book} alt="book" className={styles.bannerImg} />
    <Point
      title="Deeper Emotional Connection:"
      description="Practical steps to show genuine interest in your partner’s well-being."
    />
    <Point
      title="Enhanced Physical Intimacy:"
      description="Techniques that prioritize your partner’s pleasure, bridging the “pleasure gap”."
    />
    <Point
      title="Holistic Self-Improvement:"
      description="Lifestyle tips that boost confidence and satisfaction."
    />
    <Point
      title="Stronger Communication Skills:"
      description="Effective strategies for open, honest conversations about needs and boundaries."
    />
    <Point
      title="One guide instead of countless hours with a relationship counselor:"
      description="Save not only money but also your time."
    />
    <Point
      title="Build the perfect relationship:"
      description="Practical steps to strengthen your bond, enhance intimacy, and foster open communication."
    />
  </div>
);

export default PresaleGuidePoints;
