import { FC } from "react";
import styles from "./styles.module.scss";
import BadRel from "./../../../assets/images/bad_rel.png";
import GoodRel from "./../../../assets/images/good_rel.png";

const PresaleGuideHelper: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.heading}>Why did we build this guide?</div>
    <div className={styles.title}>
      <i>Fact:</i> Good male health doesn't always mean good relationship
    </div>

    <div className={styles.description}>
      After men resolve their sexual health issues like PE or ED the most common
      request we receive is “I want my partner so much now, but the problem is
      she doesn’t want sex as much as I do! What should I do?”.
    </div>
    <div className={styles.stats}></div>
    <div className={styles.smallTitle}>
      IS YOUR RELATIONSHIP MISSING THAT SPARK?
    </div>

    <div className={styles.paragraph}>
      Take a moment to reflect on your relationship. Do you feel excited to
      spend time with your partner, or does it <b>feel like a routine?</b> When
      was the last time you truly connected emotionally or physically? Do you
      both share laughter, affection, and meaningful conversations, or has
      <b> distance crept in?</b>
    </div>

    <img className={styles.image} src={BadRel} alt={"Bar Rels"} />

    <div className={styles.paragraph}>
      Every relationship goes through phases, but losing the spark doesn’t mean
      it’s gone forever. The question is—do you feel the excitement and
      connection you once had? Small signs like <b>growing distance</b>, less
      affection, or <b>lack of meaningful conversations</b> could mean it's time
      to take action. Reconnecting emotionally and physically requires effort
      but is completely possible with the right tools.
    </div>
    <br />
    <div className={styles.paragraph}>
      Our Guide is designed with practical advice and expert insights to help
      you <b>build the relationship you deserve.</b> This guide covers
      everything from emotional bonding techniques to intimacy-building
      practices, crafted specifically to foster a stronger, more fulfilling
      partnership.
    </div>
    <div className={styles.smallTitle}>Key points:</div>
    <div className={styles.list}>
      <div className={styles.listItem}>Proven Communication Techniques</div>
      <div className={styles.listItem}>Simple Daily Practices</div>
      <div className={styles.listItem}>Expert-Approved Intimacy Tips</div>
    </div>

    <img className={styles.image} src={GoodRel} alt={"Good Rell"} />

    <div className={styles.notification}>
      We believe that a man's intimate health is deeply connected to his ability
      to build meaningful relationships. That’s why we’re dedicated to helping
      you understand the full benefits of our guide,{" "}
      <b>Essential Skills of a Great Partner.</b>
    </div>
  </div>
);

export default PresaleGuideHelper;
