import { FC } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

export enum PageBG {
  PLANS = "PLANS",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  PRESALE_GUIDE = "PRESALE_GUIDE",
}

interface IProps {
  pageBG: PageBG;
  children: React.ReactNode;
}

const Layout: FC<IProps> = ({ children, pageBG }) => {
  const getClassByPage = () => {
    switch (pageBG) {
      case PageBG.PLANS:
        return styles.plansBg;
      case PageBG.PAYMENT_SUCCESS:
        return styles.paymentSuccessBg;
      case PageBG.QUESTIONNAIRE:
        return styles.questionnaireBg;
      case PageBG.PRESALE_GUIDE:
        return styles.presaleGuideBg;
      default:
        return styles.plansBg;
    }
  };

  return (
    <div id="wrapper" className={classNames(getClassByPage(), styles.wrapper)}>
      <main className={styles.layout}>{children}</main>
    </div>
  );
};

export default Layout;
