import { FC } from "react";
import styles from "./styles.module.scss";

const PresaleGuideBigTitle: FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
  </div>
);

export default PresaleGuideBigTitle;
