import { FC } from "react";
import { ReactComponent as BurgerIcon } from "./../../../assets/icons/burger.svg";
import { ReactComponent as CrossIcon } from "./../../../assets/icons/cross.svg";
import { ReactComponent as ClockIcon } from "./../../../assets/icons/clock.svg";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import { ReactComponent as VisaIcon } from "./../../../assets/icons/visa.svg";
import { ReactComponent as MasterCardIcon } from "./../../../assets/icons/mastercard.svg";
import { ReactComponent as PaypalIcon } from "./../../../assets/icons/paypal.svg";
import { ReactComponent as SSLIcon } from "./../../../assets/icons/ssl.svg";
import { ReactComponent as StarIcon } from "./../../../assets/icons/star.svg";
import { ReactComponent as UnionPayIcon } from "./../../../assets/icons/unionpay.svg";
import { ReactComponent as AmexIcon } from "./../../../assets/icons/amex.svg";
import { ReactComponent as JcbIcon } from "./../../../assets/icons/jcb.svg";
import { ReactComponent as DiscoverIcon } from "./../../../assets/icons/discover.svg";
import { ReactComponent as CardIcon } from "./../../../assets/icons/card.svg";
import { ReactComponent as TargetIcon } from "./../../../assets/icons/target.svg";
import { ReactComponent as ArrowLeftIcon } from "./../../../assets/icons/arrow-left.svg";
import { ReactComponent as BicepsIcon } from "./../../../assets/icons/biceps.svg";
import { ReactComponent as LockIcon } from "./../../../assets/icons/lock.svg";
import { ReactComponent as GearIcon } from "./../../../assets/icons/gear.svg";

import styles from "./styles.module.scss";

export enum IconTypes {
  BURGER = "BURGER",
  CROSS = "CROSS",
  CLOCK = "CLOCK",
  CHECK = "CHECK",
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  PAYPAL = "PAYPAL",
  SSL = "SSL",
  STAR = "STAR",
  UNIONPAY = "UNIONPAY",
  AMEX = "AMEX",
  JCB = "JCB",
  DISCOVER = "DISCOVER",
  CARD = "CARD",
  TARGET = "TARGET",
  ARROW_LEFT = "ARROW_LEFT",
  BICEPS = "BICEPS",
  LOCK = "LOCK",
  GEAR = "GEAR",
}

interface IProps {
  type: IconTypes;
  color?: string;
  size?: number | { width: number; height: number };
}

const Icon: FC<IProps> = ({ type, size = 36, color = "#000000" }) => {
  const iconByType = () => {
    switch (type) {
      case IconTypes.BURGER:
        return <BurgerIcon fill={color} />;
      case IconTypes.CROSS:
        return <CrossIcon fill={color} />;
      case IconTypes.CLOCK:
        return <ClockIcon fill={color} />;
      case IconTypes.CHECK:
        return <CheckIcon fill={color} />;
      case IconTypes.VISA:
        return <VisaIcon fill={color} />;
      case IconTypes.MASTERCARD:
        return <MasterCardIcon fill={color} />;
      case IconTypes.PAYPAL:
        return <PaypalIcon fill={color} />;
      case IconTypes.SSL:
        return <SSLIcon fill={color} />;
      case IconTypes.STAR:
        return <StarIcon fill={color} />;
      case IconTypes.UNIONPAY:
        return <UnionPayIcon fill={color} />;
      case IconTypes.AMEX:
        return <AmexIcon fill={color} />;
      case IconTypes.JCB:
        return <JcbIcon fill={color} />;
      case IconTypes.DISCOVER:
        return <DiscoverIcon fill={color} />;
      case IconTypes.CARD:
        return <CardIcon fill={color} />;
      case IconTypes.TARGET:
        return <TargetIcon fill={color} />;
      case IconTypes.ARROW_LEFT:
        return <ArrowLeftIcon fill={color} />;
      case IconTypes.BICEPS:
        return <BicepsIcon fill={color} />;
      case IconTypes.LOCK:
        return <LockIcon fill={color} />;
      case IconTypes.GEAR:
        return <GearIcon fill={color} />;
      default:
        return <></>;
    }
  };

  const getSize = () => {
    if (typeof size === "object") {
      return {
        width: `${size.width}px`,
        height: `${size.height}px`,
      };
    }

    return {
      width: `${size}px`,
      height: `${size}px`,
    };
  };

  return (
    <div className={styles.wrapper} style={{ ...getSize() }}>
      {iconByType()}
    </div>
  );
};

export default Icon;
