import { FC } from "react";
import styles from "./styles.module.scss";

const PresaleGuideNotification: FC = () => (
  <div className={styles.wrapper}>
    <b>Almost done!</b> Upgrade your plan to achieve results faster.
  </div>
);

export default PresaleGuideNotification;
