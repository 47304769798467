import { useState } from "react";
import { api, ApiPaths } from "./../api";
import { AxiosResponse } from "axios";

const useSendEmail = () => {
  const [loading, setLoading] = useState(false);

  const sendEmail = async (
    deeplink: string,
    email: string,
    braintreeUserId: string,
    onSuccess?: (response: AxiosResponse<any, any>) => void,
    onError?: (e: unknown) => void
  ) => {
    setLoading(true);

    try {
      const response = await api.post(ApiPaths.SEND_EMAIL, {
        deeplink,
        email,
        braintree_user_id: braintreeUserId,
      });

      onSuccess && onSuccess(response);
    } catch (e) {
      onError && onError(e);
    }

    setLoading(false);
  };

  return { sendEmail, loading };
};

export default useSendEmail;
