import { FC } from "react";
import styles from "./styles.module.scss";

const PresaleGuideHeader: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.logo}>
      <img
        src="https://join.the.coach/img/content/Logotip.png"
        width="40px"
        height="40px"
        alt="logo"
      />
    </div>
    <div className={styles.burger}>
      {/* <Icon type={IconTypes.BURGER} /> */}
    </div>
  </div>
);

export default PresaleGuideHeader;
