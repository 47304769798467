import { useEffect, useState } from "react";
import Layout, { PageBG } from "../../components/Layout";
import PresaleGuideHeader from "../../components/PresaleGuideHeader";
import PresaleGuideNotification from "../../components/PresaleGuideNotification";
import PresaleGuideProgress from "../../components/PresaleGuideProgress";
import PresaleGuideBigTitle from "../../components/PresaleGuideBigTitle";
import PresaleGuidePoints from "../../components/PresaleGuidePoints";
import PresaleGuideSneakPeak from "../../components/PresaleGuideSneakPeak";
import PresaleGuideCertified from "../../components/PresaleGuideCertified";
import PresaleGuideImageCompare from "../../components/PresaleGuideImageCompare";
import PresaleGuideOffer from "../../components/PresaleGuideOffer";
import PresaleGuideExcusiveOffer from "../../components/PresaleGuideExcusiveOffer";
import PresaleGuideActions from "../../components/PresaleGuideActions";
import PresaleGuideHelper from "../../components/PresaleGuideHelper";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { api, ApiPaths } from "../../api";
import * as Sentry from "@sentry/react";
import Loading from "../../components/Loading";
import styles from "./styles.module.scss";

function PresaleGuide() {
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([
    { offer: "", price: "", sales: "" },
    { offer: "", price: "", sales: "" },
    { offer: "", price: "", sales: "" },
  ]);
  const navigate = useNavigate();

  const onBuy = async () => {
    setLoading(true);
    const payment_email = localStorage.getItem("payment_email");
    const braintree_user_id = localStorage.getItem("braintree_user_id");
    const deeplink = localStorage.getItem("deeplink");

    const price = prices[currentPage].price;

    try {
      const response = await api.post(
        ApiPaths.PRE_SALE,
        JSON.stringify({
          email: payment_email,
          braintree_user_id,
          deeplink,
          price,
          type: "pdf",
        })
      );
      if (response.status === 200) {
        ReactGA.gtag("event", "upsale_purchase", {});
        navigate("/payment-success");
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    setLoading(false);
  };

  const onSkipEntirly = () => {
    navigate("/payment-success");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const onNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    switch (currentPage) {
      case 0:
        ReactGA.gtag("event", "upsale_pdf_1_firstscreen", {});
        break;
      case 1:
        ReactGA.gtag("event", "upsale_pdf_1_secondscreen", {});
        break;
      case 2:
        ReactGA.gtag("event", "upsale_pdf_1_thirdscreen", {});
        break;
    }
  }, [currentPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const add = queryParams.get("add");

    switch (add) {
      case "pdf1":
        setPrices([
          { offer: "$50", price: "$35", sales: "30%" },
          { offer: "$50", price: "$25", sales: "50%" },
          { offer: "$50", price: "$15", sales: "70%" },
        ]);
        break;
      case "pdf2":
        setPrices([
          { offer: "$30", price: "$20", sales: "33%" },
          { offer: "$30", price: "$15", sales: "50%" },
          { offer: "$30", price: "$10", sales: "66%" },
        ]);
        break;
      case "pdf3":
        setPrices([
          { offer: "$20", price: "$15", sales: "25%" },
          { offer: "$20", price: "$10", sales: "50%" },
          { offer: "$20", price: "$5", sales: "75%" },
        ]);
        break;
      default:
        setPrices([
          { offer: "$50", price: "$35", sales: "30%" },
          { offer: "$50", price: "$25", sales: "50%" },
          { offer: "$50", price: "$15", sales: "70%" },
        ]);
    }
  }, []);

  return (
    <Layout pageBG={PageBG.PRESALE_GUIDE}>
      {loading && <Loading />}
      <PresaleGuideHeader />
      {currentPage === 0 && (
        <>
          <PresaleGuideNotification />
          <PresaleGuideProgress progress={2} />
          <PresaleGuideBigTitle
            title={
              "Transform Your Relationship with Our Essential Guide for Men"
            }
            description={
              "A step-by-step guide to building emotional and physical satisfaction in your relationship by prioritizing what truly matters"
            }
          />
          <PresaleGuidePoints />
          <PresaleGuideSneakPeak />
          <PresaleGuideCertified />
          <PresaleGuideImageCompare />
          <PresaleGuideExcusiveOffer sales={prices[0].sales} />
          <PresaleGuideOffer price={prices[0].price} offer={prices[0].offer} />
          <PresaleGuideActions
            warning={true}
            secondaryText="Skip this offer and continue enrollment >"
            primaryAction={onBuy}
            secondaryAction={onNextPage}
          >
            <>ADD TO MY PROGRAM</>
          </PresaleGuideActions>
        </>
      )}
      {currentPage === 1 && (
        <>
          <PresaleGuideProgress progress={3} />
          <PresaleGuideHelper />
          <PresaleGuideActions
            warning={true}
            secondaryText="I really don't need this guide"
            primaryAction={onBuy}
            secondaryAction={onNextPage}
          >
            <>
              ADD TO MY PLAN FOR ONLY{" "}
              <span className={styles.offer}>{prices[1].offer}</span>{" "}
              {prices[1].price}
            </>
          </PresaleGuideActions>
        </>
      )}
      {currentPage === 2 && (
        <>
          <PresaleGuideProgress progress={4} />
          <PresaleGuideBigTitle
            title={`Special Discount\nFor New Users`}
            description={"One-time Offer"}
          />
          <PresaleGuideOffer
            expire
            price={prices[2].price}
            offer={prices[2].offer}
            sales={prices[2].sales}
          />
          <PresaleGuideActions
            warning={true}
            secondaryText="SKIP THIS SPECIAL OFFER"
            primaryAction={onBuy}
            secondaryAction={onSkipEntirly}
          >
            <>ADD TO MY PROGRAM WITH A {prices[2].sales} DISCOUNT</>
          </PresaleGuideActions>
        </>
      )}
    </Layout>
  );
}

export default PresaleGuide;
