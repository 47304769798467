import { useEffect } from "react";

const useLazyGoogleTag = () => {
  useEffect(() => {
    const loadGoogleTag = async () => {
      const ReactGA = (await import("react-ga4")).default;
      ReactGA.initialize(process.env.REACT_APP_GTAG as string);
    };

    const handleUserInteraction = () => {
      loadGoogleTag();
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
      document.removeEventListener("mousemove", handleUserInteraction);
    };

    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);
    document.addEventListener("mousemove", handleUserInteraction);

    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
      document.removeEventListener("mousemove", handleUserInteraction);
    };
  }, []);
};

export default useLazyGoogleTag;
