import { FC } from "react";
import styles from "./styles.module.scss";

const PresaleGuideCertified: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.title}>
      Created by Certified Relationship and Sexology Experts
    </div>
    <div className={styles.description}>
      Our guide has been crafted by a team of highly qualified specialists with
      extensive experience in relationships, sexology, and family dynamics.
      Their expertise ensures that each piece of advice is rooted in proven
      practices, helping you build a stronger, more fulfilling relationship.
    </div>

    <div className={styles.images}>
      <div className={`${styles.image} ${styles.image1}`}></div>
      <div className={`${styles.image} ${styles.image2}`}></div>
      <div className={`${styles.image} ${styles.image3}`}></div>
      <div className={`${styles.image} ${styles.image4}`}></div>
      <div className={`${styles.image} ${styles.image5}`}></div>
    </div>
  </div>
);

export default PresaleGuideCertified;
