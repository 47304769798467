import { FC } from "react";
import styles from "./styles.module.scss";

const PresaleGuideExcusiveOffer: FC<{ sales: string }> = ({ sales }) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>Exclusive Offer:</div>
    <div className={styles.description}>{sales} off for new subscribers</div>
  </div>
);

export default PresaleGuideExcusiveOffer;
