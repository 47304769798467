import { FC } from "react";
import styles from "./styles.module.scss";

interface ITitle {
  fontSize?: number;
  text: string;
  color?: string;
}

const Title: FC<ITitle> = ({ text, fontSize, color = "#141d19" }) => (
  <section className={styles.title}>
    <h1 style={{ fontSize: `${fontSize}px`, color: color }}>{text}</h1>
  </section>
);

export default Title;
