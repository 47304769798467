import { FC } from "react";
import styles from "./styles.module.scss";

const PresaleGuideActions: FC<{
  warning: boolean;
  children: React.ReactNode;
  secondaryText: string;
  primaryAction: () => void;
  secondaryAction: () => void;
}> = ({ warning, children, secondaryText, primaryAction, secondaryAction }) => (
  <div className={styles.wrapper}>
    {warning && (
      <div className={styles.warning}>
        Payment will be processed automatically based on the billing information
        you provided earlier.
      </div>
    )}
    <div className={styles.buyBtn} onClick={primaryAction}>
      {children}
    </div>
    <div className={styles.skipBtn} onClick={secondaryAction}>
      {secondaryText}
    </div>
  </div>
);

export default PresaleGuideActions;
