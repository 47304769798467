import PaymentSuccess from "./app/pages/PaymentSuccess";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { lazy, Suspense } from "react";
import ChatBot from "./app/pages/ChatBot";

// import { IntercomProvider } from "react-use-intercom";

// const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID;

const FacebookPixel = lazy(
  () => import(/* webpackChunkName: "FacebookPixel" */ "./FacebookPixel")
);
const Sentry = lazy(() => import(/* webpackChunkName: "Sentry" */ "./Sentry"));
const GoogleAnalytics = lazy(
  () => import(/* webpackChunkName: "GoogleAnalytics" */ "./GoogleAnalytics")
);
// const Fullstory = lazy(
//   () => import(/* webpackChunkName: "Fullstory" */ "./Fullstory")
// );
const Plans = lazy(
  () => import(/* webpackChunkName: "Plans" */ "./app/pages/Plans")
);

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <>Root</>,
    },
    {
      path: "/plans",
      element: (
        // <IntercomProvider appId={INTERCOM_APP_ID as string}>
        <Suspense fallback={<></>}>
          <Plans />
        </Suspense>
        // </IntercomProvider>
      ),
    },
    {
      path: "/payment-success",
      element: <PaymentSuccess />,
    },
    {
      path: "/chat-bot",
      element: (
        <Suspense fallback={<></>}>
          <ChatBot />
        </Suspense>
      ),
    },
  ]);

  return (
    <>
      {window.location.pathname !== "/chat-bot" && (
        <>
          <Sentry />
          <FacebookPixel />
          <GoogleAnalytics />
        </>
      )}
      <RouterProvider router={router} />
      {/* <Fullstory /> */}
    </>
  );
}

export default App;
