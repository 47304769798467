import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Icon, { IconTypes } from "../Icon";

let timerRef: ReturnType<typeof setInterval>;

const PresaleGuideOffer: FC<{
  expire?: boolean;
  price: string;
  offer: string;
  sales?: string;
}> = ({ expire, price, offer, sales }) => {
  const [timer, setTimer] = useState(59);

  useEffect(() => {
    if (!expire) return;

    timerRef = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => {
      if (timerRef) clearInterval(timerRef);
    };
  }, [expire, setTimer]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerRef);
    }
  }, [timer]);

  return (
    <div className={styles.wrapper}>
      {expire && (
        <div className={styles.expre}>
          <div className={styles.expireText}>
            <b>{sales} OFF</b> <br /> EXPIRES IN:
          </div>
          <div className={styles.timer}>
            00:{timer < 10 ? "0" + timer : timer}
          </div>
          <div className={styles.gift}></div>
        </div>
      )}
      <div className={styles.card} style={{ marginTop: expire ? "-16px" : 0 }}>
        <div className={styles.book}></div>
        <div className={styles.title}>Essential skills of a great partner</div>
        <div className={styles.prices}>
          <div className={styles.priceOld}>{offer}</div>
          <div className={styles.priceNew}>{price}</div>
        </div>
        <div className={styles.reviews}>
          <div>
            <div className={styles.starReviewText}>4.91</div>
            <div className={styles.starReviewStars}>
              <Icon type={IconTypes.STAR} size={14} />
              <Icon type={IconTypes.STAR} size={14} />
              <Icon type={IconTypes.STAR} size={14} />
              <Icon type={IconTypes.STAR} size={14} />
              <Icon type={IconTypes.STAR} size={14} />
            </div>
          </div>
          <div>
            <div className={styles.starReviewText}>34000+</div>
            <div className={styles.starReviewText}>5-star reviews</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresaleGuideOffer;
