import { FC, useEffect, useRef, useState } from "react";
import Talk from "talkjs";
import { api, ApiPaths } from "../../api";
import avatar from "./../../../assets/chatbot.png";

const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

const ChatBot: FC = () => {
  const chatboxRef = useRef<HTMLDivElement>(null);
  const talkSessionRef = useRef<Talk.Session | null>(null);
  const conversation = useRef<Talk.ConversationBuilder | null>(null);
  const [conversationId, setCoversationId] = useState<null | string>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const user_id = queryParams.get("user_id");
  const userid = queryParams.get("userid");

  useEffect(() => {
    Talk.ready.then(() => {
      // Define user and bot users
      const user = new Talk.User({
        id: guidGenerator(),
        name: "User",
        email: "user@example.com",
        photoUrl: "https://example.com/user-avatar.jpg",
      });

      const bot = new Talk.User({
        id: "bot",
        name: "The Coach AI",
        email: "support@the.coach",
        photoUrl: avatar,
      });

      // Initialize TalkJS session
      if (!talkSessionRef.current) {
        talkSessionRef.current = new Talk.Session({
          appId: "tnq8uuyi",
          me: user,
        });
      }

      // Create a conversation and add participants
      conversation.current = talkSessionRef.current.getOrCreateConversation(
        Talk.oneOnOneId(user, bot)
      );
      conversation.current.setParticipant(user);
      conversation.current.setParticipant(bot);

      //@ts-ignore
      setCoversationId(conversation.current.id);

      // Initialize and mount the chatbox
      const chatbox = talkSessionRef.current.createChatbox();
      chatbox.select(conversation.current);

      if (chatboxRef.current) {
        chatbox.mount(chatboxRef.current);
      }

      talkSessionRef.current?.onMessage(async (message) => {
        if (conversation.current && message.senderId !== bot.id) {
          try {
            await api.post(
              ApiPaths.CHAT_BOT,
              JSON.stringify({
                message: message,
                bot: bot,
                talk_user: user,
                user_id: user_id || userid,
              })
            );
          } catch (e) {
            console.error(e);
          }
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversationId) {
      (async () => {
        try {
          await api.post(
            ApiPaths.CHAT_BOT_START,
            JSON.stringify({
              conversation_id: conversationId,
              user_id: user_id || userid,
            })
          );
        } catch (e) {
          console.error(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  return (
    <div
      id="talkjs-container"
      ref={chatboxRef}
      style={{ height: "100vh" }}
    ></div>
  );
};

export default ChatBot;
